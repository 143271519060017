import { FC } from "react";
import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";
import "./_style.css";
import { useIntl } from "react-intl";

type GenerateNewPassworIconProps = {
  handleGenerateNewPassword: any;
};

const GenerateNewPassworIcon: FC<GenerateNewPassworIconProps> = ({ handleGenerateNewPassword }) => {
  const intl = useIntl();
  return (
    <div className="profile-generate-password-icon" data-tooltip-id="genNewPass" onClick={handleGenerateNewPassword}>
      <KTIcon iconName="arrows-circle" iconType="outline" className="iconBtn fs-1" />
      <Tooltip
        id="genNewPass"
        key="genNewPass"
        content={intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.TOOLTIP.GENERATEPASSWORD" })}
        place="top"
        variant="dark"
        className="zindex-tooltip"
      />
    </div>
  );
};
export { GenerateNewPassworIcon };
