/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../helpers";
import { useLang, setLanguage } from "../../../../localization/i18n";
import { useIntl } from "react-intl";
import axios from "axios";
import { languages } from "../../../../localization/languages/languages";

const Languages: FC = () => {
  const lang = useLang();
  const { formatMessage } = useIntl();
  const currentLanguage = languages.find((x) => x.lang === lang);

  const handleLanguageSelection = async (lang: string) => {
    await axios
      .put("/users", {
        user_properties: {
          language: lang
        }
      })
      .catch((error) => console.log(error));
    setLanguage(lang);
  };

  return (
    <div
      role="menuitem"
      className="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="bottom"
    >
      <a href="#" className="menu-link px-5">
        <span className="menu-title position-relative">
          <div className="d-flex align-items-center gap-3">
            <KTIcon iconName="geolocation-home" className="fs-2"></KTIcon>
            {formatMessage({ id: "LANGUAGE.LANGUAGE" })}
          </div>
          <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {formatMessage({ id: currentLanguage?.translationKey })}{" "}
            <img
              className="w-15px h-15px rounded-1 ms-2"
              src={currentLanguage?.flag}
              alt={formatMessage({ id: currentLanguage?.translationKey })}
            />
          </span>
        </span>
      </a>

      <div className="menu-sub menu-sub-dropdown w-175px py-4">
        {languages.map((l) => (
          <div
            className="menu-item px-3"
            key={l.lang}
            onClick={() => {
              handleLanguageSelection(l.lang);
            }}
          >
            <a href="#" className={clsx("menu-link d-flex px-5", { active: l.lang === currentLanguage?.lang })}>
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={l.flag} alt={formatMessage({ id: l.translationKey })} />
              </span>
              <div>
                <span className="fs-6">{formatMessage({ id: l.translationKey })}</span>
                <br />
                {l.lang !== currentLanguage?.lang && <span className="fs-8">{l.name}</span>}
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Languages };
