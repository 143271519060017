import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useRBAC } from "../../../../roles-permissions/core/RBACProvide";
import { toast } from "react-toastify";
import { toastSettings } from "../../../../../layout/components/toast/Toast";

const MySwal = withReactContent(Swal);

type emailsToUpdate = "all" | "one";

interface EmailOption {
  id: string;
  name: string;
  _dl_parent_program?: {
    name: string;
  };
  value: string;
}

const MktoSQAppenderForceUpdate = () => {
  const intl = useIntl();
  const { allowedUserButtonClick } = useRBAC();

  const [emailsToUpdate, setEmailsToUpdate] = useState<emailsToUpdate>("all");
  const [emailId, setEmailId] = useState<string>("");
  const [showMissingEmailIderrMessage, setShowMissingEmailIderrMessage] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [emailOptions, setEmailOptions] = useState<EmailOption[]>([]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    background: "#fafafa",
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  const handleForcedUpdate = () => {
    if (!allowedUserButtonClick) {
      toast.error(intl.formatMessage({ id: "ERROR.MESSAGE.NOTALLOWED.SUBMIT" }), toastSettings("error"));
      return;
    }

    if (emailsToUpdate === "one" && emailId === "") {
      setShowMissingEmailIderrMessage(true);
      return;
    }

    MySwal.fire({
      title: intl.formatMessage({
        id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.ALERT.TITLE"
      }),
      text: intl.formatMessage({
        id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.ALERT.DESC"
      }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({
        id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.ALERT.CONFIRM"
      }),
      cancelButtonText: intl.formatMessage({
        id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.ALERT.CANCEL"
      })
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsUpdating(true);
        if (emailsToUpdate === "one") {
          updateEmailUrl(emailId);
        } else {
          updateEmailUrl();
        }
      } else {
        Toast.fire({
          icon: "info",
          title: intl.formatMessage({
            id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.ALERT.CANCELED"
          })
        });
      }
    });
  };

  const updateEmailUrl = async (emailId?: string) => {
    if (emailId && emailId !== "") {
      await axios
        .post(`/qs_appender/update_email/${emailId}`)
        .then((response) => {
          setIsUpdating(false);
          Toast.fire({
            icon: "success",
            title: `${intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.ALERT.SUCCESEMAILUPDATE"
            })}${emailId}`
          });
        })
        .catch((error) => {
          setIsUpdating(false);
          Toast.fire({
            icon: "error",
            title: `${intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.ALERT.EMAIL.ERROR"
            })} ${emailId}: ${error.message}`
          });
        });
    }

    if (emailsToUpdate === "all") {
      await axios
        .post("/qs_appender/update_all_emails")
        .then((response) => {
          setIsUpdating(false);
          Toast.fire({
            icon: "success",
            title: intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.ALERT.SUCCESEMAILSUPDATE"
            })
          });
        })
        .catch((error) => {
          setIsUpdating(false);
          Toast.fire({
            icon: "error",
            title: `${intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.ALERT.EMAILS.ERROR"
            })} ${error.message}`
          });
        });
    }
  };

  const handleEmailsToUpdate = () => {
    setEmailsToUpdate(emailsToUpdate === "all" ? "one" : "all");
    if (emailsToUpdate === "all") {
      setShowMissingEmailIderrMessage(false);
      setEmailId("");
    }
  };

  useEffect(() => {
    axios
      .get("/marketo/settings/marketo_emails")
      .then((response) => {
        const sortedEmailOptions = response.data
          .map((email: EmailOption) => {
            const parentProgramName =
              email._dl_parent_program && email._dl_parent_program.name ? email._dl_parent_program.name : "";

            return {
              ...email,
              name: parentProgramName.length > 0 ? `${parentProgramName}.${email.name}` : email.name,
              value: email.id
            };
          })
          .sort((a: EmailOption, b: EmailOption) => a.name.localeCompare(b.name));

        setEmailOptions(sortedEmailOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="row m-3">
      <div className="col-12">
        <p className="text-muted">
          {intl.formatMessage({
            id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.DESC"
          })}
        </p>
        <div className="form-check form-switch form-check-custom form-check-solid gap-10 align-items-center">
          <span className="fs-5 fw-bold" aria-hidden="true">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.TOGGLE.TITLE"
            })}
          </span>
          <label htmlFor="mkto_qs_appender_one_email_toggle" className="form-check-label sr-only">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.TOGGLE.TITLE"
            })}
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            name="mkto_qs_appender_one_email_toggle"
            id="mkto_qs_appender_one_email_toggle"
            value={emailsToUpdate}
            checked={emailsToUpdate === "one"}
            onChange={handleEmailsToUpdate}
            aria-describedby="mkto_qs_appender_one_email_toggle_description"
          />
        </div>
        <div id="mkto_qs_appender_one_email_toggle_description" className="text-muted mt-3"></div>
        {emailsToUpdate === "one" && (
          <div className="d-flex flex-lg-column mt-6 align-items-center">
            <span className="fs-5 fw-bold w-50 w-lg-100 mb-3" aria-hidden="true">
              {intl.formatMessage({
                id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.EMAILID"
              })}
            </span>
            <label htmlFor="select_box" className="sr-only">
              {intl.formatMessage({
                id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.EMAILID"
              })}
            </label>
            <select
              name="select_box"
              data-live-search="true"
              className="form-select"
              id="select_box"
              value={emailId}
              onChange={(e) => {
                setEmailId(e.target.value);
                setShowMissingEmailIderrMessage(false);
              }}
              aria-describedby="select_box_description"
            >
              <option value="">
                {intl.formatMessage({
                  id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.EMAILID.PLACEHOLDERTEXT"
                })}
              </option>
              {emailOptions.map((email) => (
                <option key={email.id} value={email.id}>
                  {email.name} ({email.id})
                </option>
              ))}
            </select>
          </div>
        )}
        {showMissingEmailIderrMessage && emailsToUpdate === "one" ? (
          <div className="text-danger mt-3" role="alert">
            {intl.formatMessage({
              id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.EMAILID.ERROR"
            })}
          </div>
        ) : null}

        <div className="d-flex justify-content-end">
          <Button
            type="button"
            variant="danger"
            className="mt-10 settingsBtnDanger"
            onClick={handleForcedUpdate}
            disabled={isUpdating || !allowedUserButtonClick}
          >
            {!isUpdating ? (
              emailsToUpdate === "all" ? (
                intl.formatMessage({
                  id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.EMAILID.BUTTON.ALL"
                })
              ) : (
                intl.formatMessage({
                  id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.EMAILID.BUTTON.ONE"
                })
              )
            ) : (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.EMAILID.UPDATING"
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MktoSQAppenderForceUpdate;
