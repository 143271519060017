import { createContext, FC, useContext, useEffect, useState } from "react";
import { WithChildren } from "../../../helpers";
import { initialRBACView, RBACContextProps } from "./_models";
import { useAuth } from "../../auth/core/Auth";

// create Role Based Access Control (RBAC) context
const RBACContext = createContext<RBACContextProps>(initialRBACView);

// Role Based Access Control (RBAC) Provider component added in root/index.tsx
// right after to the AuthProvider to make sure current user is authenticated and the data is available
const RBACProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth();

  const [allowedUserViewPage, setAllowedUserViewPage] = useState<boolean>();
  const [allowedUserButtonClick, setAllowedUserButtonClick] = useState<boolean>();
  const [allowedUserInputEdit, setAllowedUserInputEdit] = useState<boolean>();

  console.log("currentUser : ", currentUser);

  useEffect(() => {
    setAllowedUserViewPage((prevState) => currentUser?.roles?.[0]?.name !== "Demo User" ? true : false);
    setAllowedUserButtonClick((prevState) => currentUser?.roles?.[0]?.name !== "Demo User" ? true : false);
    setAllowedUserInputEdit((prevState) => currentUser?.roles?.[0]?.name !== "Demo User" ? true : false);
  }, [currentUser]);

  console.log("allowedUserViewPage : ", allowedUserViewPage);
  console.log("allowedUserButtonClick : ", allowedUserButtonClick);
  console.log("allowedUserInputEdit : ", allowedUserInputEdit);

  return (
    <RBACContext.Provider
      value={{
        allowedUserViewPage,
        allowedUserButtonClick,
        allowedUserInputEdit
      }}
    >
      {children}
    </RBACContext.Provider>
  );
};

/**
 * @description This hook is used to get the Role Based Access Control (RBAC) context
 * @returns
 * The RBCA Provider context that includes the following properties:
 * - allowedUserViewPage: boolean
 * - allowedUserButtonClick: boolean
 * - allowedUserInputEdit: boolean
 */
const useRBAC = () => useContext(RBACContext);

export { RBACProvider, useRBAC };
