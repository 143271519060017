/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import Cookies from 'js-cookie';

import { KTIcon } from '../../../helpers';

import { auth } from '../../../firebase';

const SidebarFooter: React.FC = () => {
  const intl = useIntl();
  const [reportUrl, setReportUrl] = useState<string>("");

  useEffect(() => {

    axios.get("/account").then((response) => {

      const domainId = response?.data?.instance_version || response?.data?.domain_id || window.location.hostname.split('.')[0];
      setReportUrl(`https://${domainId}.report.insentric.net/login`);

    }).catch((error) => console.error("Error fetching account info:", error));

  }, []);

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a
        onClick={() => {

          if (!auth.currentUser) {
            return;
          }

          auth.currentUser.getIdToken().then((idToken) => {

            axios.post('/auth/idToken', { idToken }).then(() => {
              window.open(reportUrl, '_blank');
            }).catch(console.error)

          });

        }}
        target='_blank'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss='click'
        title={intl.formatMessage({ id: "SIDEBAR.BUTTON.GOTOREPORTS" })}
      >
        <span className='btn-label'>{intl.formatMessage({ id: "SIDEBAR.BUTTON.GOTOREPORTS" })}</span>
        <KTIcon iconName='chart-simple-2' className='btn-icon fs-2 m-0 p-0' />
      </a>
    </div>
  );
};

export { SidebarFooter };
